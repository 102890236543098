import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout, { LAYOUT_TYPES } from '../modules/layout';
import Main from '../modules/main';
import { CONTENT_TITLES, CONTENT_TYPES } from '../common/constants';
import { ARCHIVE_TYPES } from '../modules/ArchiveWrapper';

const getLayout = (post, contactBlock, url) => {
  //temporary fix till newsSummary is set in contenful
  const summaryObj = {
    title: post.title,
    content: {
      childMarkdownRemark: {
        excerpt: post.excerpt?.childMarkdownRemark?.html || null,
      },
    },
    image: post.featuredImage,
  };

  let layout = [
    {
      __typename: LAYOUT_TYPES.HELMET,
      summary: summaryObj,
      url: url,
    },
    {
      __typename: LAYOUT_TYPES.BREADCRUMBS,
      items: [
        {
          to: `/${CONTENT_TYPES.NEWS_BASE}`,
          label: CONTENT_TITLES.NEWS,
        },
        {
          to: null,
          label: post.title,
        },
      ],
    },
    {
      __typename: LAYOUT_TYPES.HERO_TITLE,
      title: post.title,
      hasLogo: true,
      excerpt: post.excerpt,
      subtitle: [
        post.publishedDate,
        post.category ? post.category.title : null,
        post.author,
      ],
      colorScheme: 'gray',
    },
    {
      __typename: LAYOUT_TYPES.CONTENT_BODY,
      scrollTabs: false,
      isRich: true,
      document: post?.content?.document,
      contentGrid: {
        order: [0, 0, 1],
        mb: [4, 4, 10],
        width: [1, 1, 9 / 12],
      },
      sidebarGrid: {
        width: [1, 1, 2 / 12],
        order: [1, 1, 0],
        isSticky: true,
      },
      sidebar: [
        {
          __typename: LAYOUT_TYPES.SHARE,
          summary: summaryObj,
          url: url,
        },
      ],
    },
    {
      __typename: LAYOUT_TYPES.CONTENT_BODY,
      scrollTabs: false,
      contentGrid: {
        mb: [4, 4, 10],
        width: [1, 1, 8 / 12],
      },
      sidebarGrid: {
        width: [1, 1, 3 / 12],
      },
      sidebar: [
        {
          __typename: LAYOUT_TYPES.NEWS_LETTER_WIDGET,
          formId: 'newsletter',
        },
      ],
      modules: [
        {
          __typename: LAYOUT_TYPES.COMPONENT,
          component: {
            component: LAYOUT_TYPES.ARCHIVE,
            props: {
              type: ARCHIVE_TYPES.RELATED_NEWS,
              filters: `NOT objectID:${post.contentful_id}`,
            },
          },
        },
      ],
    },
  ];

  if (contactBlock) {
    layout = [...layout, contactBlock];
  }

  return layout;
};

class NewsTemplate extends React.Component {
  render() {
    const url = this.props.location.href;
    const news = get(this.props, 'data.contentfulNews');
    const contactBlock = get(this.props, 'pageContext.contactBlock');
    const layout = getLayout(news, contactBlock, url);

    return (
      <Main {...this.props} page={news}>
        <Layout layout={layout} />
      </Main>
    );
  }
}

export default NewsTemplate;

export const pageQuery = graphql`
  query NewsPostBySlug($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      featuredImage {
        file {
          url
        }
      }
      contentful_id
      title

      author
      publishedDate(formatString: "DD MMM, YYYY")
      excerpt {
        childMarkdownRemark {
          html
        }
      }
      content {
        document: json
      }
    }
  }
`;
